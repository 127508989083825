/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Recipient = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  currency: Recipient.currency;
  billing_address: {
    address_line_1: string;
    city: string;
    state_province: string;
    country: Recipient.country;
    postal_code: string;
  };
};

export namespace Recipient {
  export enum currency {
    USD = "USD",
    GBP = "GBP",
  }

  export enum country {
    US = "US",
    GB = "GB",
  }
}
