import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInfoCircle,
  faChevronLeft,
  faCircleInfo,
  faCheck,
  faChevronsLeft,
  faBarsFilter,
  faTriangleExclamation,
  faThumbsUp,
  faCreditCard,
  faLightbulb,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faThumbsUp,
  faInfoCircle,
  faCircleInfo,
  faChevronLeft,
  faCheck,
  faChevronsLeft,
  faBarsFilter,
  faTriangleExclamation,
  faCreditCard,
  faLightbulb,
  faExclamationTriangle
);
