import { createApp } from "vue";
import { createPinia } from "pinia";
import VueGtag from "vue-gtag";
import { createI18n } from "vue-i18n";
import { router } from "./routes";
import App from "./App.vue";
import { initSentryFromEnv } from "./sentry";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import "@payout-link/icons";

import "./style.css";
import "./fonts/font-f37Lineca.css";
import "@wegift/two-components/dist/style.css";
import "@payout-link/style.css";
import { auth0 } from "./auth0";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const googleAnalyticsTag = import.meta.env.VITE_GOOGLE_ANALYTICS_TAG as string;

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  messages: {},
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);

initSentryFromEnv({ app, router });

app.use(i18n).use(router).use(auth0).use(pinia);

if (googleAnalyticsTag) {
  app.use(VueGtag, {
    config: { id: googleAnalyticsTag },
  });
}

app.mount("#app");
export default pinia;
